export const config = {
  baseUrl: process.env.VUE_APP_API,
  imageUrl: process.env.VUE_APP_IMAGE_URL,
  serviceUrl: process.env.VUE_APP_SERVICE_URL,
  socketUrl: process.env.VUE_APP_SOCKET_URL,
  login: "/v1/login",
  setStatus: "/v1/admin/setStatus",
  delete: "/v1/admin/delete",
  importTask: "/v1/admin/import",
  setRecommend: "/v1/admin/setRecommend",
  getSlideShowList: "/v1/admin/baseSetting/slideShow/list",
  getSlideShowDetail: "/v1/admin/baseSetting/slideShow/detail",
  delSlideShow: "/v1/admin/baseSetting/slideShow/del",
  addSlideShow: "/v1/admin/baseSetting/slideShow/add",
  getPosterList: "/v1/admin/baseSetting/poster/listTemplate",
  delTemplate: "/v1/admin/baseSetting/poster/delTemplate",
  addTemplate: "/v1/admin/baseSetting/poster/addTemplate",
  getTemplate: "/v1/admin/baseSetting/poster/getTemplate",
  getOssInfo: "/v1/oss/getUploadInfo",
  getVideoUploadAccess: "/v1/aliyun/getVideoUploadAccess",
  makeQrCode: "/v1/admin/makeQrCode",
  getBaseData: "/v1/admin/getBaseData",
  getExcerptList: "/v1/admin/content/excerpt/list",
  getExcerptDetail: "/v1/admin/content/excerpt/detail",
  delExcerpt: "/v1/admin/content/excerpt/del",
  editExcerpt: "/v1/admin/content/excerpt/edit",
  addExcerpt: "/v1/admin/content/excerpt/add",
  getFileList: "/v1/admin/resource/file/list",
  delFile: "/v1/admin/resource/file/del",
  addFile: "/v1/admin/resource/file/add",
  editFile: "/v1/admin/resource/file/edit",
  getFileDownloadList: "/v1/admin/content/fileDownload/list",
  getFileDownloadDetail: "/v1/admin/content/fileDownload/detail",
  delFileDownload: "/v1/admin/content/fileDownload/del",
  addFileDownload: "/v1/admin/content/fileDownload/add",
  editFileDownload: "/v1/admin/content/fileDownload/edit",
  getWorkList: "/v1/admin/other/work/list",
  getWorkCount: "/v1/admin/other/work/count",
  commentWork: "/v1/admin/other/work/comment",
  listAskQuestion: "/v1/admin/userQuestion/listAskQuestion",
  showAnswer: "/v1/admin/userQuestion/showAnswer",
  selectCheckCourse: "/v1/admin/userQuestion/selectCheckCourse",
  exportUsers: "/v1/admin/classroom/checkCourse/exportUsers",

  push: "/v1/admin/push",
  getUserInfo: "/v1/admin/user/getInfo",
  getUserList: "/v1/admin/user/list",
  setUserBan: "/v1/admin/user/setBan",
  getUserSocialList: "/v1/admin/user/social",
  getUserSocialDetail: "/v1/admin/user/social/detail",
  getUserSocialCommentList: "/v1/admin/user/social/comment",
  setUserCredit: "/v1/admin/user/setCredit",
  setUserRole: "/v1/admin/user/setRole",
  getVideoList: "/v1/admin/resource/video/list",
  editVideoName: "/v1/admin/resource/video/editVideoName",
  getCourseWxCode: "/v1/admin/classroom/course/getWxCode",
  getCourseList: "/v1/admin/classroom/course/list",
  getCourseDetail: "/v1/admin/classroom/course/detail",
  courseList: "/v1/admin/classroom/course/getList",
  bindCourse: "/v1/admin/user/bindCourse",
  isCommenting: "/v1/admin/other/work/commenting",
  addCourse: "/v1/admin/classroom/course/add",
  delCourse: "/v1/admin/classroom/course/del",
  addChapter: "/v1/admin/classroom/course/addChapter",
  getChapter: "/v1/admin/classroom/course/getChapter",
  getChapterList: "/v1/admin/classroom/course/getChapterList",
  addClass: "/v1/admin/classroom/course/addClass",
  getClassDetail: "/v1/admin/classroom/course/getClassDetail",
  setSort: "/v1/admin/classroom/course/setSort",
  createDiscount: "/v1/admin/marketing/discount/add",
  discountList: "/v1/admin/marketing/discount/list",
  getDiscountUser: "/v1/admin/marketing/discount/user",
  createdVipCard: "/v1/admin/marketing/vipCard/add",
  vipCardList: "/v1/admin/marketing/vipCard/list",
  delVipCard: "/v1/admin/marketing/vipCard/del",
  vipCardDetail: "/v1/admin/marketing/vipCard/detail",
  getCourseStudent: "/v1/admin/classroom/course/student",
  getCourseFile: "/v1/admin/classroom/course/file",
  addCourseFile: "/v1/admin/classroom/course/addFile",
  getUserStudyLog: "/v1/admin/classroom/course/userStudyLog",
  donateVip: "/v1/admin/user/donateVip",
  socialVerify: "/v1/admin/user/socialVerify",
  getUserVipLog: "/v1/admin/user/vipLog",
  addCourseStudent: "/v1/admin/classroom/course/addUser",
  sendNotify: "/v1/admin/classroom/course/sendNotify",
  closeUserVip: "/v1/admin/user/closeVip",
  getGoodsList: "/v1/admin/store/goods/list",
  addGoods: "/v1/admin/store/goods/add",
  getStoreDetail: "/v1/admin/store/goods/detail",
  getOrderList: "/v1/admin/store/order/list",
  getOrderDetail: "/v1/admin/store/order/detail",
  getBookStockList: "/v1/admin/store/bookStock/list",
  addBookStock: "/v1/admin/store/bookStock/add",
  getBookStockGet: "/v1/admin/store/bookStock/get",
  delBookStock: "/v1/admin/store/bookStock/del",
  changeBookStock: "/v1/admin/store/bookStock/stock",
  getBookStockRecordList: "/v1/admin/store/bookStock/listBookStockRecord",
  delBookStockRecord: "/v1/admin/store/bookStock/delBookStockRecord",
  exportBookStock: "/v1/admin/store/bookStock/export",

  createdSalesOrder: "/v1/admin/store/order/createSales",
  getSalesList: "/v1/admin/salesManage/sales/list",
  addSales: "/v1/admin/salesManage/sales/add",
  addSalesWx: "/v1/admin/salesManage/sales/addWeiXin",
  getStatistics: "/v1/admin/salesManage/sales/statistics",
  handleSales: "/v1/admin/salesManage/sales/handle",
  checkSales: "/v1/admin/salesManage/sales/check",
  manageStatistics: "/v1/admin/salesManage/sales/manageStatistics",
  exportSales: "/v1/admin/salesManage/sales/export",
  getSalesDetail: "/v1/admin/salesManage/sales/detail",
  getPromotionList: "/v1/admin/marketing/promotion/list",
  addPromotion: "/v1/admin/marketing/promotion/add",
  getPromotionDetail: "/v1/admin/marketing/promotion/detail",
  getPromotionUsers: "/v1/admin/marketing/promotion/users",
  addGroupBuying: "/v1/admin/marketing/groupBuying/add",
  getGroupBuyingList: "/v1/admin/marketing/groupBuying/list",
  getGroupBuyingDetail: "/v1/admin/marketing/groupBuying/detail",
  setWorkNumber: "/v1/admin/classroom/course/setWorkNumber",
  getExpressageList: "/v1/admin/salesManage/sales/expressage",
  getTeacherCourseList: "/v1/admin/teacher/course/list",
  addTeacherCourse: "/v1/admin/teacher/course/add",
  getTeacherCourseDetail: "/v1/admin/teacher/course/detail",
  addTeacherCourseChapter: "/v1/admin/teacher/chapter/add",
  getTeacherCourseChapter: "/v1/admin/teacher/chapter/getChapter",
  delTeacherCourseChapter: "/v1/admin/teacher/class/del",
  delTeacherCourseware: "/v1/admin/teacher/file/del",
  delTeacherCourse: "/v1/admin/teacher/course/del",
  delTeacherChapter: "/v1/admin/teacher/chapter/del",
  setTeacherSort: "/v1/admin/teacher/setSort",
  getTeacherChapterList: "/v1/admin/teacher/chapter/getChapterList",
  addTeacherCourseClass: "/v1/admin/teacher/class/add",
  getTeacherCourseClassDetail: "/v1/admin/teacher/class/detail",
  addTeacherCourseFile: "/v1/admin/teacher/file/add",
  getTeacherCourseFileList: "/v1/admin/teacher/file/list",
  // 教师录单
  getTeacherRecordList: "/v1/admin/teacher/record/list",
  getTeacherRecord: "/v1/admin/teacher/record/get",
  addTeacherRecord: "/v1/admin/teacher/record/add",
  verifyTeacherRecord: "/v1/admin/teacher/record/verify",
  delTeacherRecord: "/v1/admin/teacher/record/del",
  exportTeacherRecord: "/v1/admin/teacher/record/export",
  getWxCountDetail: "/v1/admin/salesManage/sales/weixinCount",
  getStatisticsDetail: "/v1/admin/salesManage/sales/statisticsDetail",
  getStatisticsCountDetail: "/v1/admin/salesManage/sales/statisticsCountDetail",
  getSalesUserCourseDetail: "/v1/admin/salesManage/sales/userCourseDetail",
  openSalesUserCourse: "/v1/admin/salesManage/sales/openCourse",
  salesChangeUser: "/v1/admin/salesManage/sales/changeSalesUser",
  getPowerList: "/v1/admin/salesManage/coursePermission/list",
  getUserCourseList: "/v1/admin/salesManage/coursePermission/userCourseList",
  userCheckCourseList:
    "/v1/admin/salesManage/coursePermission/userCheckCourseList",
  userCourseOperation: "/v1/admin/salesManage/coursePermission/apply",
  userApplyDeleted: "/v1/admin/salesManage/coursePermission/delete",
  getPowerget: "/v1/admin/salesManage/coursePermission/get",
  setPowerApprove: "/v1/admin/salesManage/coursePermission/approve",
  setPowerReject: "/v1/admin/salesManage/coursePermission/reject",
  getPowerDetail: "/v1/admin/salesManage/coursePermission/detail",
  getStatisticsChart: "/v1/admin/salesManage/sales/statisticsChart",
  getStatisticsRanking: "/v1/admin/salesManage/sales/ranking",
  setSalesGroup: "/v1/admin/salesManage/salesGroup/set",
  getSalesGroup: "/v1/admin/salesManage/salesGroup/list",
  delSalesGroup: "/v1/admin/salesManage/salesGroup/del",
  getSalesGroupUsers: "/v1/admin/salesManage/salesGroup/getUsers",
  addSalesGroupUsers: "/v1/admin/salesManage/salesGroup/addUsers",
  delSalesGroupUser: "/v1/admin/salesManage/salesGroup/delUsers",
  statisticsSalesGroup: "/v1/admin/salesManage/salesGroup/statistics",
  WorkNumber: "/v1/admin/classroom/checkCourse/setWorkNumber",
  //专栏
  addSpecialColumn: "/v1/admin/classroom/specialColumn/add",
  addSpecialColumnContent: "/v1/admin/classroom/specialColumnContent/add",
  getSpecialColumnContentList: "/v1/admin/classroom/specialColumnContent/list",
  getSpecialColumnList: "/v1/admin/classroom/specialColumn/list",
  getSpecialColumnDetail: "/v1/admin/classroom/specialColumn/detail",
  getSpecialColumnContentDetail:
    "/v1/admin/classroom/specialColumnContent/detail",
  getStudentList: "/v1/admin/classManage/class/userList",
  getMomentList: "/v1/admin/user/social",
  getClassList: "/v1/admin/classManage/class/list",
  deleteClassUser: "/v1/admin/classManage/class/delUser",
  addClassRoom: "/v1/admin/classManage/class/add",
  addClassUser: "/v1/admin/classManage/class/addUser",
  setClassRule: "/v1/admin/classManage/class/setRule",
  getClassRule: "/v1/admin/classManage/class/getRules",
  getChannelList: "/v1/admin/chat/getChatList",
  getChannelDetail: "/v1/admin/chat/getChatMessageList",
  getPunchList: "/v1/admin/classroom/checkCourse/getList",
  getPunchPlan: "/v1/admin/classroom/checkCourse/getPlan",
  getPlanDateData: "/v1/admin/classroom/checkCourse/getPlanClass",
  addPlanDate: "/v1/admin/classroom/checkCourse/addPlan",
  addPunch: "/v1/admin/classroom/checkCourse/add",
  addCheck: "/v1/admin/classroom/checkCourse/addPlanClass",
  getCheckStudent: "/v1/admin/classroom/checkCourse/getUserList",
  addCheckStudent: "/v1/admin/classroom/checkCourse/addUser",
  getUserVipList: "/v1/admin/user/vipList",
  // 会员列表-关闭会员
  closeVip: "/v1/admin/user/closeUserVip",
  setCheckSort: "/v1/admin/setSort",
  getCheckDetail: "/v1/admin/classroom/checkCourse/detail",
  getPunchDetail: "/v1/admin/classroom/checkCourse/planClassDetail",
  addClassNotification: "/v1/admin/classroom/course/sendNotify",

  // 题库
  getQuestionBank: "/v1/admin/resource/volume/lists",
  getQuestionBankDetail: "/v1/admin/resource/volume/detail",
  delQuestionBank: "/v1/admin/resource/volume/del",
  exportQuestionBank: "/v1/admin/resource/volume/export",
  addQuestionBank: "/v1/admin/resource/volume/add",
  updateQuestionBankName: "/v1/admin/resource/volume/updateName",
  updateContent: "/v1/admin/resource/volume/updateContent",
  getFormatEditData: "/v1/admin/resource/volume/getFormatEditData",

  // 批注积累
  getAnnotation: "/v1/admin/resource/annotation/lists",
  getAnnotationDetail: "/v1/admin/resource/annotation/detail",
  delAnnotation: "/v1/admin/resource/annotation/del",
  addAnnotation: "/v1/admin/resource/annotation/add",

  // 好词好句，知识扩展
  getKnowledgeList: "/v1/admin/resource/knowledge/list",
  getKnowledge: "/v1/admin/resource/knowledge/get",
  delKnowledge: "/v1/admin/resource/knowledge/del",
  addKnowledge: "/v1/admin/resource/knowledge/add",

  // 音频
  addAudio: "/v1/admin/resource/audio/add",
  getAudio: "/v1/admin/resource/audio/lists",
  editAudio: "/v1/admin/resource/audio/edit",
  delAudio: "/v1/admin/resource/audio/del",

  // 广告
  addAdv: "/v1/admin/marketing/adv/add",
  getAdvDetail: "/v1/admin/marketing/adv/detail",
  delAdv: "/v1/admin/marketing/adv/del",
  getAdv: "/v1/admin/marketing/adv/list",
  updateAdvStatus: "/v1/admin/marketing/adv/updateStatus",
  // 同步视频表
  syncVideo: "/v1/admin/resource/sync/videoDb",
  // 获得素材分类基础数据
  getBasicData: "/v1/admin/content/excerpt/getBasicData",

  //图片管理
  getImageList: "/v1/admin/resource/images/list",
  addImage: "/v1/admin/resource/images/add",

  //活动
  getAimActivity: "/v1/admin/marketing/aimActivity/list",
  delAimActivity: "/v1/admin/marketing/aimActivity/del",
  addAimActivity: "/v1/admin/marketing/aimActivity/add",
  getAimActivityDetail: "/v1/admin/marketing/aimActivity/get",
  delGoods: "/v1/admin/store/goods/del",

  answerQuestion: "/v1/common/userQuestion/answer",
  selectWorkByCheckCourse: "/common/selectWorkByCheckCourse",

  //听书
  getCateList: "/v1/admin/audioBooks/cate/list",
  setCateAdd: "/v1/admin/audioBooks/cate/add",
  setCateDel: "/v1/admin/audioBooks/cate/del",
  getCateGget: "/v1/admin/audioBooks/cate/get",
  getAllCate: "/v1/admin/audioBooks/cate/getAllCate",
  getAudioBooksList: "/v1/admin/audioBooks/list",
  setAudioBooksAdd: "/v1/admin/audioBooks/add",
  getAudioBooksGet: "/v1/admin/audioBooks/get",
  setAudioBooksDel: "/v1/admin/audioBooks/del",
  getAudioBooksDetail: "/v1/admin/audioBooks/detail",
  getDetailChapter: "/v1/admin/audioBooks/detailChapter",
  getSection: "/v1/admin/audioBooks/getSection",
  addAudioBooksChapter: "/v1/admin/audioBooks/addChapter",
  getAudioBooksChapter: "/v1/admin/audioBooks/getChapter",
  delAudioBooksChapter: "/v1/admin/audioBooks/delChapter",
  addAudioBooksSection: "/v1/admin/audioBooks/addSection",
  delAudioBooksSection: "/v1/admin/audioBooks/delSection",
  ownUserList: "/v1/admin/audioBooks/ownUserList",
  giveVip: "/v1/admin/user/giveVip",
  selectAudioBooksBooks: "/v1/admin/audioBooks/selectBooks",
  addOwn: "/v1/admin/audioBooks/addOwn",
  delOwn: "/v1/admin/audioBooks/delOwn",
  editOwn: "/v1/admin/audioBooks/editOwn",
  giveBooks: "/v1/admin/audioBooks/user/giveBooks",
  audioBooksVipList: "/v1/admin/vip/list",
  audioBooksVipGet: "/v1/admin/vip/get",
  audioBooksVipSet: "/v1/admin/vip/set",
  vipSelectList: "/v1/admin/vip/selectList",

  //游戏模块
  getGameList: "/v1/admin/game/list",
  setGameAdd: "/v1/admin/game/add",
  gameGet: "/v1/admin/game/get",
  gameDel: "/v1/admin/game/del",
  gameListPass: "/v1/admin/game/listPass",
  gameAddPass: "/v1/admin/game/addPass",
  gameDelPass: "/v1/admin/game/delPass",
  gameGetPass: "/v1/admin/game/getPass",
  //班级通讯
  getClassChatList: "/v1/admin/chat/getClassChatList", //获取班级聊天列表
  getUserChatRecords: "/v1/admin/chat/getUserChatRecords", //获取班级聊天列表
  sendMessage: "/v1/admin/chat/sendMessage", //获取班级聊天列表
  clearUnreadMessage: "/v1/admin/chat/clearUnreadMessage", //清空未读消息
  getClassUserInfo: "/v1/admin/chat/userInfo", //获取用户信息

  // 推广员
  listLevel: "/v1/admin/salesManage/promote/listLevel",
  addLevel: "/v1/admin/salesManage/promote/addLevel",
  getLevel: "/v1/admin/salesManage/promote/getLevel",
  delLevel: "/v1/admin/salesManage/promote/delLevel",
  setLevelStatus: "/v1/admin/salesManage/promote/setLevelStatus",
  listPromoter: "/v1/admin/salesManage/promote/listPromoter",
  selectListAdviser: "/v1/admin/salesManage/promote/selectListAdviser",
  setPromoterStatus: "/v1/admin/salesManage/promote/setPromoterStatus",
  infoCheck: "/v1/admin/salesManage/promote/infoCheck",
  addPromoter: "/v1/admin/salesManage/promote/addPromoter",
  selectListProduct: "/v1/admin/salesManage/promote/selectListProduct",
  getEditProducts: "/v1/admin/salesManage/promote/getEditProducts",
  addProducts: "/v1/admin/salesManage/promote/addProducts",
  ListApplyForMoneyRecord:
    "/v1/admin/salesManage/promote/ListApplyForMoneyRecord",
  selectListPromoter: "/v1/admin/salesManage/promote/selectListPromoter",
  applyAudit: "/v1/admin/salesManage/promote/applyAudit",
  applyPaid: "/v1/admin/salesManage/promote/applyPaid",
  listPromoterRecord: "/v1/admin/salesManage/promote/listPromoterRecord",
  invalidRecord: "/v1/admin/salesManage/promote/invalidRecord",

  listAllPromoterRecord: "/v1/admin/salesManage/promote/listAllPromoterRecord",
  exportAllPromoterRecords:
    "/v1/admin/salesManage/promote/exportAllPromoterRecords",
  addPromoterRecord: "/v1/admin/salesManage/promote/addPromoterRecord",

  //直播
  addUser: "/v1/admin/live/addUser",
  editUser: "/v1/admin/live/editUser",
  setCourse: "/v1/admin/live/setCourse",
  setCourseClass: "/v1/admin/live/setCourseClass",
  getLiveUser: "/v1/admin/live/getLiveUser",
  getLiveCourseList: "/v1/admin/live/getLiveCourseList",
  getLiveCourseClassList: "/v1/admin/live/getLiveCourseClassList",
  setTeacherStatus: "/v1/admin/live/setTeacherStatus",
  delCourseClass: "/v1/admin/live/delCourseClass",
  addLiveCourseUser: "/v1/admin/live/addLiveCourseUser",
  getLiveCourseUser: "/v1/admin/live/getLiveCourseUser",
  delLiveCourseUser: "/v1/admin/live/delLiveCourseUser",
  getLoginLinked: "/v1/admin/live/getLoginLinked",
  setLiveTrial: "/v1/admin/live/setTrial",
  getLiveTrialList: "/v1/admin/live/getTrialList",
  getLiveUserProfile: "/v1/admin/live/getLiveUserProfile",

  transferList: "/v1/admin/transfer/list",
  transferDetail: "/v1/admin/transfer/detail",
  noticeList: "/v1/admin/notice/list",
  selectStudents: "/v1/admin/notice/selectStudents",
  publish: "/v1/admin/notice/publish",
  noticeDetail: "/v1/admin/notice/detail",
  noticeEdit: "/v1/admin/notice/edit",
  listMember: "/v1/admin/team/listMember",
  addMember: "/v1/admin/team/addMember",
  getMember: "/v1/admin/team/getMember",
  selectMembers: "/v1/admin/team/selectMembers",
  statisticsTop: "/v1/admin/team/statistics/top",
  statisticsData: "/v1/admin/team/statistics/data",
  myWxData: "/v1/admin/team/statistics/myWxData",
  delMember: "/v1/admin/team/delMember",
  setMemberStatus: "/v1/admin/team/setMemberStatus",

  correctList: "/v1/admin/userCorrection/correctList",
  verifyCorrect: "/v1/admin/userCorrection/verifyCorrect",
  detailCorrect: "/v1/admin/userCorrection/detailCorrect",

  exportExpressage: "/v1/admin/salesManage/sales/exportExpressage",
  selectSalesAttribution: "/v1/admin/salesManage/sales/selectSalesAttribution",
  exportOrder: "/v1/admin/store/exportOrder",
  showComments: "/v1/admin/user/showComments",
  socialComment: "/v1/admin/user/socialComment",
  replyComment: "/v1/admin/user/replyComment",
  listAppletStartupDiagram: "/v1/admin/appletConfig/listAppletStartupDiagram",
  delAppletStartupDiagram: "/v1/admin/appletConfig/delAppletStartupDiagram",
  addAppletStartupDiagram: "/v1/admin/appletConfig/addAppletStartupDiagram",
  getAppletStartupDiagram: "/v1/admin/appletConfig/getAppletStartupDiagram",
  setAppletStartupDiagramStatus:
    "/v1/admin/appletConfig/setAppletStartupDiagramStatus",
  listAuthor: "/v1/admin/poem/listAuthor",
  addAuthor: "/v1/admin/poem/addAuthor",
  getAuthor: "/v1/admin/poem/getAuthor",
  setAuthorSort: "/v1/admin/poem/setAuthorSort",
  delAuthor: "/v1/admin/poem/delAuthor",
  listType: "/v1/admin/poem/listType",
  setTypeSort: "/v1/admin/poem/setTypeSort",
  addType: "/v1/admin/poem/addType",
  getType: "/v1/admin/poem/getType",
  delType: "/v1/admin/poem/delType",
  listDynasty: "/v1/admin/poem/listDynasty",
  addDynasty: "/v1/admin/poem/addDynasty",
  getDynasty: "/v1/admin/poem/getDynasty",
  delDynasty: "/v1/admin/poem/delDynasty",
  setDynastySort: "/v1/admin/poem/setDynastySort",
  getPoemBaseData: "/v1/common/getPoemBaseData",
  listPoem: "/v1/admin/poem/listPoem",
  delPoem: "/v1/admin/poem/delPoem",
  addPoem: "/v1/admin/poem/addPoem",
  getPoem: "/v1/admin/poem/getPoem",
  userMessageStationList: "/v1/admin/userMessageStation/list",
  userMessageStationDetail: "/v1/admin/userMessageStation/detail",
  unreadLimit: "/v1/admin/userMessageStation/unreadLimit",
  selectCreator: "/v1/admin/resource/volume/selectCreator",
  evaluationList: "/v1/admin/evaluation/evaluationList",
  addEvaluation: "/v1/admin/evaluation/addEvaluation",
  getEvaluation: "/v1/admin/evaluation/getEvaluation",
  delEvaluation: "/v1/admin/evaluation/delEvaluation",
  evaluationItemList: "/v1/admin/evaluation/evaluationItemList",
  delEvaluationItem: "/v1/admin/evaluation/delEvaluationItem",
  addEvaluationItem: "/v1/admin/evaluation/addEvaluationItem",
  getEvaluationItem: "/v1/admin/evaluation/getEvaluationItem",
  getEvaluationItemOptions: "/v1/admin/evaluation/getEvaluationItemOptions",
  saveEvaluationItemOptions: "/v1/admin/evaluation/saveEvaluationItemOptions",
  evaluationItemScoreList: "/v1/admin/evaluation/evaluationItemScoreList",
  getEvaluationItemScore: "/v1/admin/evaluation/getEvaluationItemScore",
  addEvaluationItemScore: "/v1/admin/evaluation/addEvaluationItemScore",
  delEvaluationItemScore: "/v1/admin/evaluation/delEvaluationItemScore",
  getEvaluationItemSpend: "/v1/admin/evaluation/getEvaluationItemSpend",
  saveEvaluationItemSpend: "/v1/admin/evaluation/saveEvaluationItemSpend",
  getEvaluationQrcode: "/v1/admin/evaluation/getEvaluationQrcode",
  teacherClassAdd: "/v1/admin/teacher/class/newAdd",
  teacherFileAdd: "/v1/admin/teacher/file/newAdd",
  listCustomer: "/v1/admin/customer/listCustomer",
  detailCustomer: "/v1/admin/customer/detailCustomer",
  listCustomerConsult: "/v1/admin/customer/listCustomerConsult",
  delCustomerConsult: "/v1/admin/customer/delCustomerConsult",
  listCustomerRefund: "/v1/admin/customer/listCustomerRefund",
  addCustomer: "/v1/admin/customer/addCustomer",
  getCustomer: "/v1/admin/customer/getCustomer",
  editCustomer: "/v1/admin/customer/editCustomer",
  bindUser: "/v1/admin/customer/bindUser",
  unbindUser: "/v1/admin/customer/unbindUser",
  addCustomerConsult: "/v1/admin/customer/addCustomerConsult",
  getCustomerConsult: "/v1/admin/customer/getCustomerConsult",
  addCustomerRefund: "/v1/admin/customer/addCustomerRefund",
  getCustomerRefund: "/v1/admin/customer/getCustomerRefund",
  delCustomerRefund: "/v1/admin/customer/delCustomerRefund",
  listCustomerFollowRecord: "/v1/admin/customer/listCustomerFollowRecord",
  addCustomerFollowRecord: "/v1/admin/customer/addCustomerFollowRecord",
  getCustomerFollowRecord: "/v1/admin/customer/getCustomerFollowRecord",
  delCustomerFollowRecord: "/v1/admin/customer/delCustomerFollowRecord",
  listCustomerSale: "/v1/admin/customer/listCustomerSale",
  listCustomerDistributionRule:
    "/v1/admin/customer/listCustomerDistributionRule",
  selectCounselor: "/v1/admin/customer/selectCounselor",
  addCustomerDistributionRule: "/v1/admin/customer/addCustomerDistributionRule",
  getCustomerDistributionRule: "/v1/admin/customer/getCustomerDistributionRule",
  delCustomerDistributionRule: "/v1/admin/customer/delCustomerDistributionRule",
  changeAttributor: "/v1/admin/customer/changeAttributor",
  gainCustomer: "/v1/admin/customer/gainCustomer",
  studentProfileList: "/v1/admin/studentProfile/studentProfileList",
  selectStudentTags: "/v1/admin/studentProfile/selectStudentTags",
  listStudentProfileRefund: "/v1/admin/studentProfile/listStudentProfileRefund",
  addStudentProfileRefund: "/v1/admin/studentProfile/addStudentProfileRefund",
  getStudentProfileRefund: "/v1/admin/studentProfile/getStudentProfileRefund",
  delStudentProfileRefund: "/v1/admin/studentProfile/delStudentProfileRefund",
  studentProfileDetail: "/v1/admin/studentProfile/studentProfileDetail",
  getStudentProfile: "/v1/admin/studentProfile/getStudentProfile",
  studentTagList: "/v1/admin/studentProfile/studentTagList",
  editStudentProfile: "/v1/admin/studentProfile/editStudentProfile",
  addStudentTag: "/v1/admin/studentProfile/addStudentTag",
  delStudentTag: "/v1/admin/studentProfile/delStudentTag",
  getStudentTag: "/v1/admin/studentProfile/getStudentTag",
  listStudentProfileSale: "/v1/admin/studentProfile/listStudentProfileSale",
  userQuestionList: "/v1/admin/studentProfile/userQuestionList",
  userCourseTransferRecordList:
    "/v1/admin/studentProfile/userCourseTransferRecordList",
  userWorkList: "/v1/admin/studentProfile/userWorkList",
  listStudentProfileState: "/v1/admin/studentProfile/listStudentProfileState",
  addStudentProfileState: "/v1/admin/studentProfile/addStudentProfileState",
  getStudentProfileState: "/v1/admin/studentProfile/getStudentProfileState",
  delStudentProfileState: "/v1/admin/studentProfile/delStudentProfileState",
  userCreditLogList: "/v1/admin/studentProfile/userCreditLogList",
  userSocialList: "/v1/admin/studentProfile/userSocialList",
  userUnionCourseList: "/v1/admin/studentProfile/userUnionCourseList",
  getAllTeacherGroup: "/v1/admin/teacherGroup/getAllTeacherGroup",
  selectTeacherLeader: "/v1/admin/teacherGroup/selectTeacherLeader",
  selectTeacherGroupParent: "/v1/admin/teacherGroup/selectTeacherGroupParent",
  addTeacherGroup: "/v1/admin/teacherGroup/addTeacherGroup",
  getAllTeacherGroupUsers: "/v1/admin/teacherGroup/getAllTeacherGroupUsers",
  delTeacherGroup: "/v1/admin/teacherGroup/delTeacherGroup",
  addTeacherGroupUsers: "/v1/admin/teacherGroup/addTeacherGroupUsers",
  delTeacherGroupUsers: "/v1/admin/teacherGroup/delTeacherGroupUsers",
  changeClassTeacher: "/v1/admin/classManage/class/changeClassTeacher",
  classManageDel: "/v1/admin/classManage/class/del",
  selectCourse: "/v1/common/selectCourse",
  closeCourse: "/v1/admin/studentProfile/closeCourse",
  getEditUserCheckCourse: "/v1/admin/studentProfile/getEditUserCheckCourse",
  editUserCheckCourse: "/v1/admin/studentProfile/editUserCheckCourse",
  delUserStudentTag: "/v1/admin/studentProfile/delUserStudentTag",
  checkCourseCopy: "/v1/admin/classroom/checkCourse/copy",
  exportAllUser: "/v1/admin/classroom/checkCourse/exportAllUser",
  orderList: "/v1/admin/live/orderList",
  getOrder: "/v1/admin/live/getOrder",
  toastAdList: "/v1/admin/toastAd/list",
  toastAdAdd: "/v1/admin/toastAd/add",
  toastAdGet: "/v1/admin/toastAd/get",
  toastAdDel: "/v1/admin/toastAd/del",
  toastAdSetStatus: "/v1/admin/toastAd/setStatus",
  attachmentFileList: "/v1/admin/email/attachmentFileList",
  addAttachmentFile: "/v1/admin/email/addAttachmentFile",
  getAttachmentFile: "/v1/admin/email/getAttachmentFile",
  delAttachmentFile: "/v1/admin/email/delAttachmentFile",
  sendRecordList: "/v1/admin/email/sendRecordList",
  sendRecordDetail: "/v1/admin/email/sendRecordDetail",
  emailSend: "/v1/admin/email/send",
  salesDataFileApplyList: "/v1/admin/salesDataFileApply/list",
  salesDataFileApplyDel: "/v1/admin/salesDataFileApply/del",
  salesDetail: "/v1/admin/salesDataFileApply/salesDetail",
  selectSales: "/v1/admin/salesDataFileApply/selectSales",
  salesDataFileApplyAdd: "/v1/admin/salesDataFileApply/add",
  salesDataFileApplyGet: "/v1/admin/salesDataFileApply/get",
  salesDataFileApplyVerify: "/v1/admin/salesDataFileApply/verify",
  salesDataFileApplyResend: "/v1/admin/salesDataFileApply/resend",
  schoolAdd: "/v1/admin/school/add",
  schoolList: "/v1/admin/school/list",
  getSchool: "/v1/admin/school/get",
  delSchool: "/v1/admin/school/del",
  recordList: "/v1/admin/school/recordList",
  getWxacode: "/v1/admin/school/getWxacode",
  giftList: "/v1/admin/giftCredit/templateList",
  giftAdd: "/v1/admin/giftCredit/addTemplate",
  getGift: "/v1/admin/giftCredit/getTemplate",
  setGiftStatus: "/v1/admin/giftCredit/setTemplateStatus",
  delGift: "/v1/admin/giftCredit/delTemplate",
  selectGift: "/v1/admin/giftCredit/selectTemplate",
  giving: "/v1/admin/giftCredit/giving",
  ruleList: "/v1/admin/giftCredit/ruleList",
  addRule: "/v1/admin/giftCredit/addRule",
  getRule: "/v1/admin/giftCredit/getRule",
  delRule: "/v1/admin/giftCredit/delRule",
  levelAvatarFrameList: "/v1/admin/levelAvatarFrame/list",
  levelAvatarFrameDel: "/v1/admin/levelAvatarFrame/del",
  levelAvatarFrameAdd: "/v1/admin/levelAvatarFrame/add",
  levelAvatarFrameGet: "/v1/admin/levelAvatarFrame/get",
  enterpriseList: "/v1/admin/enterprise/enterpriseList",
  addEnterprise: "/v1/admin/enterprise/addEnterprise",
  setEnterpriseStatus: "/v1/admin/enterprise/setEnterpriseStatus",
  setEnterpriseDefault: "/v1/admin/enterprise/setEnterpriseDefault",
  getEnterprise: "/v1/admin/enterprise/getEnterprise",
  delEnterprise: "/v1/admin/enterprise/delEnterprise",
  detailEnterprise: "/v1/admin/enterprise/detailEnterprise",
  addEnterpriseMchid: "/v1/admin/enterprise/addEnterpriseMchid",
  getEnterpriseMchid: "/v1/admin/enterprise/getEnterpriseMchid",
  delEnterpriseMchid: "/v1/admin/enterprise/delEnterpriseMchid",
  selectEnterprise: "/v1/admin/enterprise/selectEnterprise",
  deviceList: "/v1/admin/user/deviceList",
  unbindDevice: "/v1/admin/user/unbindDevice",
  editPhone: "/v1/admin/user/editPhone",
  editAllowPc: "/v1/admin/user/editAllowPc",
  resetPassword: "/v1/admin/user/resetPassword",
  setNewStudent: "/v1/admin/user/setNewStudent",
  getNewStudentList: "/v1/admin/user/getNewStudentList",
  getNewStudentDetail: "/v1/admin/user/getNewStudentDetail",
  getAppUpdate: "/v1/admin/appConfig/getAppUpdate",
  saveAppUpdate: "/v1/admin/appConfig/saveAppUpdate",
  // 话题设置
  socialThemeList: "/v1/admin/socialTheme/list",
  socialThemeAdd: "/v1/admin/socialTheme/add",
  socialThmeGet: "/v1/admin/socialTheme/get",
  socialThemeRecommend: "/v1/admin/socialTheme/recommend",
  setSocialThemeSort: "/v1/admin/socialTheme/setSort",


  //排课
  setScheduling:"/v1/admin/courseScheduling/setScheduling",
  getScheduling:"/v1/admin/courseScheduling/getScheduling",
  setSchedulingTeacher:"/v1/admin/courseScheduling/setTeacher",
  addCourseSchedulingCourse:"/v1/admin/courseScheduling/addCourse",
  getCourseSchedulingCourse:"/v1/admin/courseScheduling/getCourse",
  getCourseSchedulingTeacherList:"/v1/admin/courseScheduling/getTeacherList",
  getSchedulingDetail:"/v1/admin/courseScheduling/getSchedulingDetail",
  setSchedulingLog:"/v1/admin/courseScheduling/setSchedulingLog",
  courseSchedulingNotify:"/v1/admin/courseScheduling/courseSchedulingNotify",
  batchAddScheduling:"/v1/admin/courseScheduling/batchAddScheduling",
  setSchedulingStudent:"/v1/admin/courseScheduling/setSchedulingStudent",
  getSchedulingStudent:"/v1/admin/courseScheduling/getSchedulingStudent",
  getSchedulingLog:"/v1/admin/courseScheduling/getSchedulingLog",

  //意向客户
  getIntendedCustomerList:"/v1/admin/salesManage/intendedCustomer/getList",
  getIntendedCustomerDetail:"/v1/admin/salesManage/intendedCustomer/getDetail",
  setIntendedCustomer:"/v1/admin/salesManage/intendedCustomer/set",
  setIntendedCustomerVisitLog:"/v1/admin/salesManage/intendedCustomer/setLog",
  getIntendedCustomerVisitCount:"/v1/admin/salesManage/intendedCustomer/getCount",
  getIntendedCustomerStatistics:"/v1/admin/salesManage/intendedCustomer/statistics",
};
